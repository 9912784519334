<template>
  <section class="percentageBar">
    <div class="percentageBar__percentageHeader">
      <p class="percentageBar__title">Porcentaje de ocupación</p>
      <span class="percentageBar__loadingText skeletonS" v-if="isLoading"></span>
      <span class="percentageBar__percentageValue" v-else>{{ roomOccupationPercentage + " %" }}</span>
    </div>
    <div :class="['percentageBar__barShow', isLoading && 'skeletonS']">
      <div class="percentageBar__barShow percentageBar__barShow--fill" :style="{ width: roomOccupationPercentage + '%' }" v-show="!isLoading"></div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters("Occupation", ["usersInRooms", "loadingIn"]),
    isLoading() {
      return this.loadingIn("usersInRooms");
    },
    roomOccupationPercentage() {
      const { total = 0, users = 0 } = this.usersInRooms;
      const percentage = (users / total) * 100;
      return isNaN(percentage) ? 0 : Math.round(percentage);
    },
  },
  beforeDestroy() {
    this.$store.commit(`Occupation/changeLoadingIn`, { name: "usersInRooms", value: true });
  },
};
</script>

<style lang="scss">
.percentageBar {
  @include Flex(column);
  gap: 10px;
  width: 100%;
  padding: 20px;
  font-family: $sec_font;
  font-size: 25px;
  border-radius: 15px;
  background: #ffffff;
  box-shadow: 0px 1px 4px 0px #00000040;
  &__percentageHeader {
    @include Flex(row, space-between);
    width: 100%;
  }
  &__title {
    font-weight: lighter;
  }
  &__barShow {
    height: 11px;
    width: 100%;
    border-radius: 7px;
    background-color: #f0f0f0;
    &--fill {
      height: 100%;
      width: 0;
      max-width: 100%;
      background-color: $primary-color;
      transition: 1s ease-in-out;
    }
  }
  &__loadingText {
    height: 37px;
    width: 45px;
    border-radius: 12px;
  }
}
</style>
